import React, { useState, useEffect } from "react"
import styled from "styled-components"
import queryString from "query-string"
import { ArticlePage, theme } from "@rily/components"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { GetPreviewArticleDocument } from "../../../types/generatedTypes"
import { slug } from "../../../utils/slug"

const GET_PREVIEW_ARTICLE = gql`
  ${GetPreviewArticleDocument}
`

const Container = styled.div`
  background-color: ${theme.colors.white};
  overflow-x: hidden;
`

const ArticlePreviewPage = ({ location }) => {
  const articleId = queryString.parse(location.search).articleId
  const { loading, error, data } = useQuery(GET_PREVIEW_ARTICLE, {
    variables: {
      id: articleId,
    },
  })

  const [article, setArticle] = useState<any>(data)

  // grab any recipes and transform data before sending it down to the page component

  const getRecipes = async data => {
    if (data?.body) {
      const newBody = await Promise.all(
        data.body.map(async block => {
          if (block.recipe) {
            let newRecipe = {}

            await fetch(`https://api.rily.co/recipes/${block.recipe.recipeId}`)
              .then(response => response.json())
              .then(data => {
                newRecipe = {
                  ...block,
                  recipe: {
                    ...data,
                    cookTime: data.cook_time,
                    ingredients: data.recipe_ingredient,
                  },
                }
              })

            return newRecipe
          } else {
            return block
          }
        })
      )
      let footer = {
        articleTheme: theme.categoryColors[data.category],
        tags:
          data.tags.map(tag => {
            return tag.name
          }) || [],
        author: {
          name: data.author.name,
        },
        articles: [{ articleType: "Popular Articles", articles: [] }],
      }

      // get related recipe images
      if (data.related_recipes) {
        const fetchedRecipes = await Promise.all(
          data.related_recipes
            .map(async recipe => {
              let newRecipe: any = {}
              await fetch(`https://api.rily.co/recipes/${recipe.id}`)
                .then(response => response.json())
                .then(data => {
                  newRecipe = {
                    ...recipe,
                    images: data.images,
                    title: recipe.name,
                    linkUrl: `/recipes/${slug(recipe.name)}`,
                  }
                })

              return newRecipe
            })
            .filter(async recipe => {
              return recipe.images !== undefined
            })
        )

        // filter out imageless recipes before adding to footer
        // in case of cms user error
        footer.articles.push({
          articleType: "Related Recipes",
          articles: fetchedRecipes.filter((recipe: any) => {
            return recipe.images !== undefined
          }),
        })
      }

      // get popular articles

      await fetch(`https://editorial.rily.co/articles?_limit=6`)
        .then(res => res.json())
        .then(popArticles => {
          footer.articles[0].articles = popArticles
            .filter(post => {
              return post.title !== data.title
            })
            .map(post => {
              return {
                id: post.id,
                images: [post.header.url],
                title: post.title,
                linkUrl: `/articles/${slug(post.title)}`,
              }
            })
            .slice(0, 5)
        })

      // get author info
      await fetch(`https://api.rily.co/authors/${data.author.authorId}`)
        .then(response => response.json())
        .then(data => (footer.author = data))

      const newArticle = { ...data, body: newBody, footer: footer }

      setArticle(newArticle)
    }
  }

  useEffect(() => {
    if (data?.article) {
      getRecipes(data.article)
    }
  }, [data])

  return (
    <Container>
      {!loading && article && <ArticlePage data={article} />}
    </Container>
  )
}

export default ArticlePreviewPage
